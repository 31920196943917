import _ from 'lodash';
import ACLRequests from '../../../utils/requestClasses/acl';
import { getDependencyContainer } from '@/dependencyContainer';
import DependenciesList from '@/dependencyContainer/dependenciesList';

function createPayload(role, resource) {
    return {
        role,
        resource,
        action: ['*'],
        attributes: ['non-private']
    };
}

export default {

    async initializeACL({ commit }) {
        const aclReq = await getDependencyContainer().get(DependenciesList.ACLRequests);
        const acl = await aclReq.getRules('components');
        commit('setACL', acl);
    },

    async editRules({ state, commit }, { add = [], remove = [] } = {}) {
        const currentACL = state.acl;

        let newACL = _.differenceWith(currentACL, remove, _.isEqual);

        newACL = _.uniqWith([...newACL, ...add], _.isEqual);

        const response = await ACLRequests.updateRule('components', newACL);
        commit('setACL', response);
    },

    async addRule({ state, commit, dispatch }, { rule }) {
        await dispatch('editRules', { add: [rule] });
    },

    async setModuleRoles({ state, commit }, { module, roles }) {
        const currentACL = state.acl;

        // Filter out rules that for the module
        const excludedCurrentModule = currentACL.filter(rule => {
            return !rule.resource.startsWith(module);
        });

        const acls = roles.map(role => {
            return createPayload(role, `${module}*`);
        });

        const response = await ACLRequests.updateRule('components', [...excludedCurrentModule, ...acls]);
        commit('setACL', response);
    }
};
