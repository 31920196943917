export default class DependencyContainer {
    constructor() {
        this.registered = new Map();
        this.singletons = new Map();
    }

    register(className, factoryFn) {
        if (typeof className !== 'string') {
            throw new Error('A name (string) must be passed');
        }

        this.registered.set(className, { factoryFn, isSingleton: false });
    }

    registerSingleton(className, factoryFn) {
        if (typeof className !== 'string') {
            throw new Error('A name (string) must be passed');
        }

        this.registered.set(className, { factoryFn, isSingleton: true });
    }

    get(classObject) {
        if (typeof classObject !== 'string') {
            throw new Error('A name (string) must be passed');
        }

        const register = this.registered.get(classObject);

        if (!register) {
            throw new Error(`Dependency ${classObject.name} not registered`);
        }

        if (register.isSingleton && this.singletons.get(classObject)) {
            return this.singletons.get(classObject);
        }
        const dependency = register.factoryFn();
        if (register.isSingleton) {
            this.singletons.set(classObject, dependency);
            return this.singletons.get(classObject);
        }
        return dependency;
    }
}
