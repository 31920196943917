import AppsRequests from '@/utils/requestClasses/apps';
import ACLRequests from '@/utils/requestClasses/acl';
import AuthConfigRequests from '@/utils/requestClasses/authConfig';
import ComponentRequests from '@/utils/requestClasses/component';
import ConfigRequests from '@/utils/requestClasses/config';
import IndexRequests from '@/utils/requestClasses/dataIndex';
import DocsRequests from '@/utils/requestClasses/docs';
import EngineVersionRequests from '@/utils/requestClasses/engineVersion';
import FlowRequests from '@/utils/requestClasses/flow';
import InsightsRequests from '@/utils/requestClasses/insight';
import PublicFilesRequests from '@/utils/requestClasses/publicFiles';
import QuotaRequests from '@/utils/requestClasses/quota';
import StatusRequests from '@/utils/requestClasses/status';
import UserRequests from '@/utils/requestClasses/user';
import AWSProvider from '@/services/ModulesProvider/AWSProvider';
import Dependencies from './dependenciesList';

export default function register(DependencyContainer) {
    DependencyContainer.registerSingleton(Dependencies.ACLRequests, async () => {
        return new ACLRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.AppsRequests, async () => {
        return new AppsRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.AuthConfigRequests, async () => {
        return new AuthConfigRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.ComponentRequests, async () => {
        return new ComponentRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.ConfigRequests, async () => {
        return new ConfigRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.IndexRequests, async () => {
        return new IndexRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.DocsRequests, async () => {
        return new DocsRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.EngineVersionRequests, async () => {
        return new EngineVersionRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.FlowRequests, async () => {
        return new FlowRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.InsightsRequests, async () => {
        return new InsightsRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.PublicFilesRequests, async () => {
        return new PublicFilesRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.QuotaRequests, async () => {
        return new QuotaRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.StatusRequests, async () => {
        return new StatusRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.UserRequests, async () => {
        return new UserRequests();
    });

    DependencyContainer.registerSingleton(Dependencies.ModulesProvider, async () => {
        return new AWSProvider();
    });
}
