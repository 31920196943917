export default {
    ACLRequests: 'ACLRequests',
    ModulesProvider: 'ModulesProvider',
    IndexRequests: 'IndexRequests',
    AuthConfigRequests: 'AuthConfigRequests',
    FlowRequests: 'FlowRequests',
    ComponentRequests: 'ComponentRequests',
    AppsRequests: 'AppsRequests',
    DocsRequests: 'DocsRequests',
    ConfigRequests: 'ConfigRequests',
    EngineVersionRequests: 'EngineVersionRequests',
    InsightsRequests: 'InsightsRequests',
    PublicFilesRequests: 'PublicFilesRequests',
    QuotaRequests: 'QuotaRequests',
    StatusRequests: 'StatusRequests',
    UserRequests: 'UserRequests'
};
